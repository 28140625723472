<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title
      >Billing Schedule ({{ billingSchedule.length }})
    </template>
    <template v-slot:body>
      <v-container class="p-0">
        <table width="100%">
          <tbody>
            <template>
              <tr v-for="(row, index) in billingSchedule" :key="index">
                <td
                  class="font-size-14 font-weight-500"
                  align="center"
                  valign="top"
                >
                  {{ index + 1 }}.
                </td>
                <td>
                  <DatePicker
                    v-model="row.date"
                    :disabled="detail == 'custom' ? false : true"
                    key="visit-show-schedule-start-date"
                    id="visit-show-schedule-start-date"
                    placeholder="Start Date"
                    v-on:change="setDay(row.date, index)"
                  />
                </td>
                <td>
                  <v-text-field
                    readonly
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    :value="row.dayName"
                  ></v-text-field>
                </td>
                <td width="5%" v-if="detail == 'custom'">
                  <v-icon color="red" v-on:click="removeFile(index)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
              <tr>
                <td
                  v-if="detail == 'custom' && billingSchedule?.length < 20"
                  colspan="3"
                >
                  <v-btn
                    depressed
                    tile
                    color="cyan white--text"
                    class="ml-1"
                    v-on:click="addMore()"
                    >Add More</v-btn
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-if="detail == 'custom'"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="saveSchedule"
      >
        Save
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import moment from "moment-timezone";
import DatePicker from "@/view/components/QDatePicker.vue";
import { SET_BILLING_LIST } from "@/core/services/store/common.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonFunctionMixin],
  name: "job-ctx-confirm-schedule",
  data() {
    return {};
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: String,
      default: null,
    },
  },
  methods: {
    saveSchedule() {
      this.$store.commit(SET_BILLING_LIST, this.billingSchedule);
      this.$emit("close", true);
    },
    addMore() {
      let currentDate = new Date();
      const date = currentDate.toISOString().split("T")[0];
      const dayName = currentDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      this.billingSchedule.push({
        date: date,
        dayName: dayName,
      });
    },
    removeFile(index) {
      if (this.billingSchedule.length > 1) {
        this.billingSchedule.splice(index, 1);
      }
    },
    setDay(date, index) {
      let formated = new Date(date);
      const dayName = formated.toLocaleDateString("en-US", {
        weekday: "long",
      });
      this.billingSchedule[index].dayName = dayName;
    },
  },
  components: {
    Dialog,
    DatePicker,
  },
  mounted() {},
  computed: {
    ...mapGetters(["billingSchedule"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
};
</script>
